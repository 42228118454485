import { faCircleCheck, faCircleInfo, faCircleXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'

export default function Loading(props)
{
    const list_errors = props.errors.map((error, index) => <li key={index}>{error}</li>);
    const icons = {
        error: faCircleXmark,
        warning: faTriangleExclamation,
        info: faCircleInfo,
        success: faCircleCheck
    }

    if (props.title !== "")
    {
        return (
            <Row className="pr1 justify-content-md-center tc">
                <Col className={props.class}>
                    <p><FontAwesomeIcon icon={icons[props.class]}/>&nbsp;{props.title}</p>
                    {props.loading ? <Spinner animation="grow" variant="primary" /> : <></>}
                    {props.errors.length > 0 ? <ul className='center-list'>{list_errors}</ul> : <></>}
                </Col>
            </Row>
          )
    } else {
        return (<></>);
    }
}