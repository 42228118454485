import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { GetPago, UploadCOMFiles } from '../../Services/Net/ProveedoresService';
import { Currency } from '../../Utils/Utils';
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faCode, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import Title from '../Title';

export default function PaymentDetail()
{
    let params = useParams();

    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});
    const [paymentData, setPaymentData] = useState(null);
    const [filesToUpload, setfilesToUpload] = useState({pdf: null, xml: null});

    // Select file PDF
    function onFilePDFChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            pdf: event.target.files[0]
        });
    };

    // Select file XML
    function onFileXMLChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            xml: event.target.files[0]
        });
    };

    function UploadFiles()
    {
        const formData = new FormData();

        setStatus({
            title: "Cargando archivos...", 
            errors: [], 
            loading: true, 
            class: "info"});

        formData.append(
            "complemento.pdf",
            filesToUpload.pdf,
            filesToUpload.pdf.name
        );

        formData.append(
            "complemento.xml",
            filesToUpload.xml,
            filesToUpload.xml.name
        );

        const abortController = new AbortController();
        UploadCOMFiles(formData, abortController.signal, params.paymentId).then((response) => {
            if (abortController.signal.aborted)
                return;
            
            if (response.ok)
            {
                response.json().then((data) =>{
                    if (data.errors.length > 0)
                    {
                        setStatus({
                            title: "Los documentos presentan los siguientes errores: ", 
                            errors: data.errors, 
                            loading: false, 
                            class: "error"});
                    } else {
                        setStatus({
                            title: "Complemento procesado correctamete", 
                            errors: [], 
                            loading: false, 
                            class: "success"});
                        setPaymentData(data.pago);
                    }
                });
            } else{
                response.json().then((data) =>{
                    setStatus({
                        title: "Error al procesar la peticion", 
                        errors: [data.Message], 
                        loading: false, 
                        class: "error"});
                });
            }
        }).catch(function(error) { 
            if (!abortController.signal.aborted)
            {
                console.log(error);
                setStatus({
                    title: "Error de conexión", 
                    errors: ["Compruebe su conexion de internet"], 
                    loading: false, 
                    class: "error"});
            }
        });
    }

    //FETCH
    useEffect(() => {
        const abortController = new AbortController();

        setStatus({
            title: "Cargando Pago...", 
            errors: [], 
            loading: true, 
            class: "info"});

        GetPago(abortController.signal, params.paymentId).then((response) => {
            if (abortController.signal.aborted)
                return;
            
            if (response.ok)
            {
                response.json().then((data) =>{
                    setPaymentData(data);
                    setStatus({
                        title: "", 
                        errors: [], 
                        loading: false, 
                        class: "info"});
                });
            } else{
                response.json().then((data) =>{
                    setStatus({
                        title: "Error al procesar la peticion", 
                        errors: [data.Message], 
                        loading: false, 
                        class: "error"});
                });
            }
        }).catch(function(error) { 
            if (!abortController.signal.aborted)
                {
                    console.log(error);
                    setStatus({
                        title: "Error de conexión", 
                        errors: ["Compruebe su conexion de internet"], 
                        loading: false, 
                        class: "error"});
                }
        });

        return () => {abortController.abort();};
    }, [params.paymentId]);

    //REFRESH
    useEffect(() => {
    }, [paymentData]);

    return(
        <section className='p-4'>
            <Loading {...status}/>
            {paymentData ? <>
            <Title text={`Detalles del pago #${paymentData.id}`} icon={faFileInvoiceDollar}/>
            <PaymentResume {...paymentData}/>
            <PaymentActions 
                {...paymentData}
                {...filesToUpload} 
                onFilePDFChange={(e) => onFilePDFChange(e)}
                onFileXMLChange={(e) => onFileXMLChange(e)}
                UploadFiles={() => UploadFiles()}
                isLoading={status.loading}
            />
            <PaymentInvoices invoices={paymentData.facturas}/>
            </> : <></>}
        </section>
    );
}

function PaymentResume(props)
{
    const STATUS_LABELS = ["Falta complemento pago", "Completado"];
    const STATUS_VARIANTS = ["info","success"];

    return(
        <Container fluid>
            <Alert variant={STATUS_VARIANTS[props.status]}>
                <Row className='text-center'>
                    <Col>
                        <span>{moment(props.fecha).format("ddd D MMM YYYY h:mm A")}</span>
                    </Col>
                    <Col>
                        <span><strong>{STATUS_LABELS[props.status]}</strong></span>
                    </Col>
                    <Col>
                        <span>TOTAL {Currency.format(props.total)}</span>
                    </Col>
                </Row>
            </Alert>
        </Container>
    );
}

function PaymentActions(props)
{
    if (props.status === 0)
    {
        return(
        <Form className='text-center'>
            <h3 className='mt-3'>Subir complemento de pago</h3>
            <h4 className='orangered mt-3'>Documentos Obligatorios</h4>
            <Row className="justify-content-md-center">
                <Col>
                    <Form.Group className="mb-3 orangered">
                        <Form.Label>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>&nbsp;
                            Adjuntar PDF *
                        </Form.Label>
                        <Form.Control type="file" accept='.pdf' onChange={(e) => props.onFilePDFChange(e)}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3 orangered">
                        <Form.Label>
                            <FontAwesomeIcon icon={faCode}/>&nbsp;
                            Adjuntar XML *
                        </Form.Label>
                        <Form.Control type="file" accept='.xml' onChange={(e) => props.onFileXMLChange(e)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button 
                        variant="success" 
                        className="item-center" 
                        disabled={props.xml === null || props.pdf === null || props.isLoading}
                        onClick={() => props.UploadFiles()}
                        >
                        <FontAwesomeIcon icon={faCloudArrowUp}/>&nbsp;
                        Subir Archivos
                    </Button>
                </Col>
            </Row>
        </Form>
        );
    } else {
        return(<></>);
    }

}

function PaymentInvoices(props)
{
    const list_invoices = props.invoices.map((inv, index) => 
        <tr key={index}>
            <td>{inv.factura}</td>
            <td>{moment(inv.fechaDesgloseContrarecibo).format("ddd D MMM YYYY")}</td>
            <td>{Currency.format(inv.subtotal)}</td>
            <td>{Currency.format(inv.iva)}</td>
            <td>{Currency.format(inv.total)}</td>
        </tr>
    );

    return(
        <Container fluid className='mt-2'>
            <h2>Facturas</h2>
            <Table>
                <thead>
                    <tr>
                        <th>Factura</th>
                        <th>Fecha</th>
                        <th>Subtotal</th>
                        <th>IVA</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {list_invoices}
                </tbody>
            </Table>
        </Container>
    )
}