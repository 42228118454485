import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare} from '@fortawesome/free-solid-svg-icons';

export default function Checked(props)
{
    if (props.ison)
    {
        return(<FontAwesomeIcon icon={faCheckSquare}/>)
    } else {
        return(<FontAwesomeIcon icon={faSquare}/>);
    }
}