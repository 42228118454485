import { faArrowDownShortWide, faArrowUpWideShort, faArrowsRotate, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Row, 
    Col, 
    Container,
    InputGroup,
    FormControl,
    Form, 
    ButtonGroup,
    Button,
    DropdownButton,
    Dropdown} from 'react-bootstrap';

export default function CommunFilters(props)
{
    const list_sort_ops = props.sort_ops.map((ops, index) => 
        <option key={index} value={ops.value}>{ops.label}</option>
    );

    const list_filters = props.filters.map((f,index) => 
        <Dropdown.Item key={index} onClick={() => props.setRangeFilter(index)}>{f}</Dropdown.Item>
    )

    return(
        <Container fluid>
            <Row className='p-2'>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                    <InputGroup>
                        <InputGroup.Text id="search-filter">
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </InputGroup.Text>
                        <FormControl
                        placeholder={props.search_ph}
                        aria-label="search"
                        aria-describedby="search-filter"
                        value={props.search_value}
                        onChange={(e) => props.setSearch(e.target.value)}
                        disabled={props.disabled}
                        />
                    </InputGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                    <InputGroup>
                        <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title={props.filters[props.range_filter_value]}
                        >
                            {list_filters}
                        </DropdownButton>
                        { props.range_filter_value === 0 ? 
                        <>
                        <FormControl 
                            type="date" 
                            placeholder="Fecha Inicio"
                            value={props.date_value}
                            onChange={(e) => props.setDateFilter(e.target.value)}
                            disabled={props.disabled}
                            max={props.date_end_value}
                        />
                        <FormControl 
                            type="date" 
                            placeholder="Fecha Final"
                            value={props.date_end_value}
                            onChange={(e) => props.setDateFilterEnd(e.target.value)}
                            disabled={props.disabled}
                            min={props.date_value}
                        />
                        </> : <></>}
                    </InputGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4} className="displayflex">
                    <InputGroup>
                        <InputGroup.Text>
                            Ordenar
                        </InputGroup.Text>
                        <Form.Select 
                        value={props.sort_filter_value} 
                        onChange={(e) => props.setSortFilter(e.target.value)}
                        disabled={props.disabled}
                        defaultValue={moment().format("YYYY-MM-DD")}
                        >
                            {list_sort_ops}
                        </Form.Select>
                    </InputGroup>&#8195;
                    <ButtonGroup>
                        <Button
                            variant={props.sort_filter_type === 1 ? 'dark' : 'outline-dark'}
                            onClick={() => props.setSortFilterType(1)}
                        ><FontAwesomeIcon icon={faArrowDownShortWide}/></Button>
                        <Button
                            variant={props.sort_filter_type === -1 ? 'dark' : 'outline-dark'}
                            onClick={() => props.setSortFilterType(-1)}
                        ><FontAwesomeIcon icon={faArrowUpWideShort}/></Button>
                    </ButtonGroup>&#8195;
                    <Button variant="outline-dark" onClick={() => props.requestRefresh()} disabled={props.disabled}>
                        <FontAwesomeIcon icon={faArrowsRotate}/>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}