import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ReceiptDetail from '../Components/DetailsViews/ReceiptDetail';
import CommunFilters from '../Components/CommunFilters';
import ReceiptsList from '../Components/ListViews/ReceiptsList';
import Loading from '../Components/Loading';
import { GetContrarecibosRange } from '../Services/Net/ProveedoresService';

export default function ReceiptsPage(props)
{
    return(
        <Routes>
            <Route path=':receiptId' element={
                <ReceiptDetail/>
            }/>
            <Route path='*' element={
                <ReceiptsSearch 
                    rFilters={props.receiptsFilters} 
                    changeReceiptsFilters={(f) => props.changeReceiptsFilters(f)}/>
            }/>
        </Routes>
    );
}

function ReceiptsSearch(props)
{
    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});
    const [receipts, setReceipts] = useState([]);
    const [refresh, setRefresh] = useState(true);

    //FETCH
    useEffect(() => {
        
        const abortController = new AbortController();
        const formatdate = "YYYY-MM-DD HH:mm:ss";
        const datefilter = props.rFilters.datefilter;
        const datefilterend = props.rFilters.datefilterend;
        setStatus({
            title: "Cargando contrarecibos...", 
            errors: [], 
            loading: true, 
            class: "info"});

        let dateStart = datefilter.startOf('day').format(formatdate);
        let dateEnd = datefilterend.endOf('day').format(formatdate);

        switch(props.rFilters.rangefilter)
        {
            case 1:
                {
                    dateStart = moment().subtract(7, 'days').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
            case 2:
                {
                    dateStart = moment().subtract(1, 'months').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
                default:{}
        }

        GetContrarecibosRange(abortController.signal, dateStart, dateEnd).then((response) => {
                if (abortController.signal.aborted)
                        return;
                if (response.ok)
                {
                    response.json().then((data) =>{
                        setReceipts(data);
                        if (data.length === 0)
                        {
                            setStatus({
                                title: "No se encontraron resultados.", 
                                errors: [], 
                                loading: false, 
                                class: "warning"});
                        } else {
                            setStatus({
                                title: "", 
                                errors: [], 
                                loading: false, 
                                class: "info"});
                        }
                    });
                } else {
                    response.json().then((data) =>{
                        setStatus({
                            title: "Error al procesar la peticion", 
                            errors: [data.Message], 
                            loading: false, 
                            class: "error"});
                    });
                }
            }).catch(function(error) { 
                if (!abortController.signal.aborted)
                {
                    console.log(error);
                    setStatus({
                        title: "Error de conexión", 
                        errors: ["Compruebe su conexion de internet"], 
                        loading: false, 
                        class: "error"});
                }
            });

        return () => {abortController.abort();};
    }, [props.rFilters.datefilter, props.rFilters.datefilterend, props.rFilters.rangefilter, refresh]);

    //CHANGE FILTERS
    useEffect(() => {

    }, [props.rFilters.search, props.rFilters.sortfilter, props.rFilters.sortfilterType]);

    const sortOptions = [
        {value:2, label:"Fecha"},
        {value:1, label:"Status"},
        {value:0, label:"Folio"},
        {value:4, label:"Monto"}];

    const search = props.rFilters.search;
    const sortfilter = props.rFilters.sortfilter;
    const sortfilterType = props.rFilters.sortfilterType;

    //SHORT AND FILTER
    const list_array = [];
    receipts.forEach(element => {
        if (search.length > 0)
        {
            if (element.id.toString().includes(search) || element.facturas.toLowerCase().includes(search.toLowerCase()))
            {
                list_array.push(Object.values(element));
            }
        } else {
            list_array.push(Object.values(element));
        }
    });

    list_array.sort(function(a, b){
        if (a[sortfilter] > b[sortfilter]) {
            return sortfilterType;
            } else if (a[sortfilter] < b[sortfilter]) {
            return sortfilterType*-1;
            } else {
            return 0;
            }
    });

    const sorted_data = []; 
    
    list_array.forEach(element => {
        sorted_data.push({
            id: element[0],
            status: element[1],
            fecha: element[2],
            fechaPago: element[3],
            concepto: element[5],
            facturas: element[6],
            total: element[9],
        });
    });

    return(
        <section>
            <CommunFilters
            search_ph="Buscar Folio o Factura"
            search_value={search}
            filters={["Rango fechas", "Ultima semana", "Ultimo mes"]}
            setSearch={(v) => props.changeReceiptsFilters({...props.rFilters, search: v})}
            date_value={props.rFilters.datefilter.format("YYYY-MM-DD")}
            setDateFilter={(v) => props.changeReceiptsFilters({...props.rFilters, datefilter: moment(v)})}
            date_end_value={props.rFilters.datefilterend.format("YYYY-MM-DD")}
            setDateFilterEnd={(v) => props.changeReceiptsFilters({...props.rFilters, datefilterend: moment(v)})}
            sort_filter_value={sortfilter}
            setSortFilter={(v) => props.changeReceiptsFilters({...props.rFilters, sortfilter: v})}
            sort_filter_type={sortfilterType}
            setSortFilterType={(v) => props.changeReceiptsFilters({...props.rFilters, sortfilterType: v})}
            sort_ops={sortOptions}
            range_filter_value={props.rFilters.rangefilter}
            setRangeFilter={(v) => props.changeReceiptsFilters({...props.rFilters, rangefilter: v})}
            disabled={status.loading}
            requestRefresh={() => setRefresh(!refresh)}
            />
            <Loading {...status}/>
            {receipts.length > 0 ? <ReceiptsList receipts={sorted_data}/> : <></>}
        </section>
    );
}