import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import CommunFilters from '../Components/CommunFilters';
import OrderDetail from '../Components/DetailsViews/OrderDetail';
import OrdersList from '../Components/ListViews/OrdersList';
import Loading from '../Components/Loading';
import { GetOrdersRange } from '../Services/Net/ProveedoresService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function OrdersPage(props)
{
    return(
        <Routes>
            <Route path=':orderId' element={
                <OrderDetail/>
            }/>
            <Route path='*' element={
                <OrdersSearch 
                    oFilters={props.ordersFilters} 
                    changeOrdersFilters={(f) => props.changeOrdersFilters(f)}/>
            }/>
        </Routes>
    );
}

function OrdersSearch(props)
{
    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});
    const [orders, setOrders] = useState([]);
    const [refresh, setRefresh] = useState(true);
    
    //FETCH
    useEffect(() => {
        
        const abortController = new AbortController();
        const formatdate = "YYYY-MM-DD HH:mm:ss";
        const datefilter = props.oFilters.datefilter;
        const datefilterend = props.oFilters.datefilterend;
        setStatus({
            title: "Cargando ordenes...", 
            errors: [], 
            loading: true, 
            class: "info"});

        let dateStart = datefilter.startOf('day').format(formatdate);
        let dateEnd = datefilterend.endOf('day').format(formatdate);

        switch(props.oFilters.rangefilter)
        {
            case 1:
                {
                    dateStart = moment().subtract(7, 'days').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
            case 2:
                {
                    dateStart = moment().subtract(1, 'months').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
            case 3:
                {
                    dateStart = moment().subtract(40, 'days').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
                default:{}
        }

        GetOrdersRange(
            abortController.signal, dateStart, dateEnd).then((response) => {
                if (abortController.signal.aborted)
                        return;
                if (response.ok)
                {
                    response.json().then((data) =>{
                        setOrders(props.oFilters.rangefilter === 3 ? data.filter(f => f.status === 0) : data);
                        if (data.length === 0)
                        {
                            setStatus({
                                title: "No se encontraron resultados.", 
                                errors: [], 
                                loading: false, 
                                class: "warning"});
                        } else {
                            setStatus({
                                title: "", 
                                errors: [], 
                                loading: false, 
                                class: "info"});
                        }
                    });
                } else {
                    response.json().then((data) =>{
                        setStatus({
                            title: "Error al procesar la peticion", 
                            errors: [data.Message], 
                            loading: false, 
                            class: "error"});
                    });
                }
            }).catch(function(error) { 
                if (!abortController.signal.aborted)
                {
                    console.log(error);
                    setStatus({
                        title: "Error de conexión", 
                        errors: ["Compruebe su conexion de internet"], 
                        loading: false, 
                        class: "error"});
                }
            });

        return () => {abortController.abort();};
    }, [props.oFilters.datefilter, props.oFilters.datefilterend, props.oFilters.rangefilter, refresh]);

    //CHANGE FILTERS
    useEffect(() => {

    }, [props.oFilters.search, props.oFilters.sortfilter, props.oFilters.sortfilterType]);

    const sortOptions = [
        {value:2, label:"Fecha"},
        {value:1, label:"Status"},
        {value:0, label:"Folio"},
        {value:9, label:"Monto"}];

    const search = props.oFilters.search;
    const sortfilter = props.oFilters.sortfilter;
    const sortfilterType = props.oFilters.sortfilterType;

    //FILTER SEARCH

    const list_array = [];
    orders.forEach(element => {
        if (search.length > 0)
        {
            if (element.id.toString().includes(search) || element.factura.toLowerCase().includes(search.toLowerCase()))
            {
                list_array.push(Object.values(element));
            }
        } else {
            list_array.push(Object.values(element));
        }
    });
    //SORT
    list_array.sort(function(a, b){
        if (a[sortfilter] > b[sortfilter]) {
            return sortfilterType;
            } else if (a[sortfilter] < b[sortfilter]) {
            return sortfilterType*-1;
            } else {
            return 0;
            }
    });

    //SORTED DATA
    const sorted_data = []; 
    list_array.forEach(element => {
        sorted_data.push({
            id: element[0],
            status: element[1],
            fechaHora: element[2],
            factura: element[3],
            total: element[9],
            fechaCarga: element[19]
        });
    });

    return(
        <section>
            <CommunFilters
            search_ph="Buscar Folio o Factura"
            search_value={search}
            filters={["Rango fechas", "Ultima semana", "Ultimo mes", "Pendientes"]}
            setSearch={(v) => props.changeOrdersFilters({...props.oFilters, search: v})}
            date_value={props.oFilters.datefilter.format("YYYY-MM-DD")}
            setDateFilter={(v) => props.changeOrdersFilters({...props.oFilters, datefilter: moment(v)})}
            date_end_value={props.oFilters.datefilterend.format("YYYY-MM-DD")}
            setDateFilterEnd={(v) => props.changeOrdersFilters({...props.oFilters, datefilterend: moment(v)})}
            sort_filter_value={sortfilter}
            setSortFilter={(v) => props.changeOrdersFilters({...props.oFilters, sortfilter: v})}
            sort_filter_type={sortfilterType}
            setSortFilterType={(v) => props.changeOrdersFilters({...props.oFilters, sortfilterType: v})}
            sort_ops={sortOptions}
            range_filter_value={props.oFilters.rangefilter}
            setRangeFilter={(v) => props.changeOrdersFilters({...props.oFilters, rangefilter: v})}
            disabled={status.loading}
            requestRefresh={() => setRefresh(!refresh)}
            />
            <div className='tc blue'>
                <small><FontAwesomeIcon icon={faInfoCircle}/> Si no ves reflejada la orden compra,  favor de comunicarte al departamento de compras.</small>
            </div>
            <Loading {...status}/>
            {orders.length > 0 ? <OrdersList orders={sorted_data}/> : <></>}
        </section>
    );
}