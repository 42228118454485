import React from 'react';
import { Navigate } from "react-router-dom";

export default function NotFound(props)
{
    if (props.user)
    {
        return(
            <Navigate to="/dashboard/orders" replace/>
            );
    } else 
    {
        return(
            <Navigate to="/login" replace/>
            );
    }
}