import { GetBaseUrlApi } from "../../AppConfig"; 
import cookie from 'react-cookies';

export async function GET(direction,bsignal,params=null)
{
    const user = cookie.load('hrsj_prv_userdata');
    const token = user === undefined ? "" : user.Token;

    var url = new URL(GetBaseUrlApi()+direction);
    
    if (params)
        url.search = new URLSearchParams(params).toString();

    var miInit = { 
    signal: bsignal,
    method: "GET",
    headers: {
        'Authorization': 'Bearer '+token,
        'Accept': 'application/json'
        }
    };

    return await fetch(url.href,miInit);
}

export async function POST(content,direction,bsignal)
{
    const user = cookie.load('hrsj_prv_userdata');
    const token = user === undefined ? "" : user.Token;

    var miInit = { 
    signal: bsignal,
    method: "POST",
    headers: {
        'Authorization': 'Bearer '+token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
    body: JSON.stringify(content)
    };

    return await fetch(GetBaseUrlApi()+direction,miInit);
}

export async function UPLOAD(formData,direction,bsignal)
{
    const user = cookie.load('hrsj_prv_userdata');
    const token = user === undefined ? "" : user.Token;

    var miInit = { 
        signal: bsignal,
        method: "POST",
        headers: {
            'Authorization': 'Bearer '+token,
            'Accept': 'application/json'
            },
        body: formData
    };

    return await fetch(GetBaseUrlApi()+direction,miInit);
}

export async function PUT(content,direction,bsignal)
{
    const user = cookie.load('hrsj_prv_userdata');
    const token = user === undefined ? "" : user.Token;

    var miInit = { 
    signal: bsignal,
    method: "PUT",
    headers: {
        'Authorization': 'Bearer '+token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
    body: JSON.stringify(content)
    };

    return await fetch(GetBaseUrlApi()+direction,miInit);
}

export async function DELETE(direction,bsignal)
{
    const user = cookie.load('hrsj_prv_userdata');
    const token = user === undefined ? "" : user.Token;

    var miInit = { 
    signal: bsignal,
    method: "DELETE",
    headers: {
        'Authorization': 'Bearer '+token,
        'Accept': 'application/json'
        }
    };

    return await fetch(GetBaseUrlApi()+direction,miInit);
}

export async function LOGIN(user, pass, bsignal)
{
    var miInit = { 
    signal: bsignal,
    method: "POST",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
    body: JSON.stringify({Username: user, Password: pass})
    };

    return await fetch(GetBaseUrlApi()+"login/auth/proveedor",miInit);
}

export async function HandleError(response)
{
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
  } else {
      return response.text();
  }
}

export function GetError(result,defError)
{
    if (result.Msg)
    {
        return result.Msg;
    } else if (result.Message)
    {
        return result.Message;
    } else {
        return defError;
    }
}