import {GetHospital} from './Utils/Utils';

export const DebugMode = window.location.href.includes("localhost:3000");

export function GetBaseUrlApi()
{
    const hindex = GetHospital();
    const localProduction = false;
    
    if (DebugMode)
    {
        if (localProduction)
        {
            return hindex === 'LC' ? "http://172.16.1.31:808/sahnet/api/" : "http://10.0.40.17:808/net/api/";
        } else {
            return "http://localhost:6549/api/";
        }
    }
    else
    {
        return hindex === 'LC' ? "https://webserviceslc.hrsj.com.mx/sahnet/api/" : "https://webservicesvr.hrsj.com.mx/net/api/";
    }
}

export function GetTitleHospital()
{
    const hindex = GetHospital();

    return hindex === 'LC' ? "Lazaro Cardenas" : "Valle Real";
}

export function GetIndexHospital()
{
    const hindex = GetHospital();

    return hindex === 'LC' ? 0 : 1;
}