import React, {useState } from 'react';
import {GetHospital} from './Utils/Utils';
import {
  Routes,
  Route
} from "react-router-dom";
import { LOGIN, HandleError, GetError } from "./Services/Net/NetService";
import cookie from 'react-cookies';
import './App.scss';
import DashboardPage from './Pages/DashboardPage';
import LoginPage from './Pages/LoginPage';
import NotFound from './Pages/NotFound';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

function App() {
  return (
    <Main/>
  );
}

function Main()
{
  const userCookie = cookie.load('hrsj_prv_userdata');
  const [remember, setRemember] = useState(cookie.load('hrsj_prv_defaultuser')!==undefined);
  const [logstatus, setLogstatus] = useState({title: "", errors: [], loading: false, class: "error"});
  const [user, setUser] = useState(userCookie === undefined ? null : userCookie);
  const [hospital, setHospital] = useState(GetHospital());

  function Login(name,pass)
  {
    const abortController = new AbortController();

    setLogstatus({
      title: "Ingresando...", 
      errors: [], 
      loading: true, 
      class: "info"});

    LOGIN(name,pass,abortController.signal).then(
      (response) =>{
        if (abortController.signal.aborted)
        return;

        if (response.ok)
        {
          response.json().then(function(data) {
            setLogstatus({
              title: "", 
              errors: [], 
              loading: false, 
              class: "info"});
            var expires = new Date();
            expires.setDate(expires.getDate() + 1);
            cookie.save("hrsj_prv_userdata",data,
            {
              expires: expires,
            });
            if(remember)
            {
              cookie.save("hrsj_prv_defaultuser",name,
              {
                expires: new Date(2999,1,1,0,0,0),
              });
            } else {
              cookie.remove("hrsj_prv_defaultuser");
            }
            setUser(data);
          });
        } else {
          HandleError(response).then((error) => {
            setLogstatus({
              title: GetError(error), 
              errors: [], 
              loading: false, 
              class: "error"});
          });
        }
      }
    ).catch(function(error) { 
      if (!abortController.signal.aborted){
        setLogstatus({
          title: "Error de conexión", 
          errors: ["Compruebe su conexion de internet"], 
          loading: false, 
          class: "error"});
      }
    });
  }

  function LogOut()
  {
    cookie.remove("hrsj_prv_userdata");
    setUser(undefined);
  }

  function ChangeHospital(val)
  {
    setHospital(val);
    cookie.save("hrsj_prv_hospital",val);
  }

  function SwitchHosp()
  {
    const newhospital = hospital === 'LC' ? 'VR' : 'LC';
    setHospital(newhospital);
    cookie.save("hrsj_prv_hospital",newhospital);
  }

  return (
    <main className="App" id="main">
      <Routes>
        <Route path="login" element={
          <LoginPage user={user} 
          hospital={hospital} 
          status={logstatus} 
          remember={remember}
          onSubmit={(u,p) => Login(u,p)}
          ChangeHosp={(v) => ChangeHospital(v)}
          setRemember={(v) => setRemember(v)}
          />
        }/>
        <Route path="dashboard/*" element={
          <DashboardPage user={user} hospital={hospital}
          LogOut={() => LogOut()}
          SwitchHosp={() => SwitchHosp()}/>
        }/>
        <Route path="*" element={<NotFound user={user} />}/>
      </Routes>
    </main>
  );
}

export default App;
