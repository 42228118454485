import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function Footer()
{
    return(<>
        <div className='footer'>
            <Row>
                <Col className='mt-1'>
                © 2020. Todos los Derechos Reservados. Contacto <FontAwesomeIcon icon={faPhone}/> 33 1078 8900 Ext. 3009-3128
                </Col>
            </Row>
        </div>
    </>)
}