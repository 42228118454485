import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import CommunFilters from '../Components/CommunFilters';
import PaymentList from '../Components/ListViews/PaymentList';
import Loading from '../Components/Loading';
import { GetPagosRange } from '../Services/Net/ProveedoresService';
import PaymentDetail from '../Components/DetailsViews/PaymentDetail';

export default function PaymentsPage(props)
{
    return(
        <Routes>
            <Route path=':paymentId' element={
                <PaymentDetail/>
            }/>
            <Route path='*' element={
                <PaymentsSearch 
                    pFilters={props.paymentsFilters} 
                    changePaymentsFilters={(f) => props.changePaymentsFilters(f)}/>
            }/>
        </Routes>
    );
}

function PaymentsSearch(props)
{
    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});
    const [payments, setPayments] = useState([]);
    const [refresh, setRefresh] = useState(true);

    //FETCH
    useEffect(() => {
        
        const abortController = new AbortController();
        const formatdate = "YYYY-MM-DD HH:mm:ss";
        const datefilter = props.pFilters.datefilter;
        const datefilterend = props.pFilters.datefilterend;
        setStatus({
            title: "Cargando pagos...", 
            errors: [], 
            loading: true, 
            class: "info"});

        let dateStart = datefilter.startOf('day').format(formatdate);
        let dateEnd = datefilterend.endOf('day').format(formatdate);

        switch(props.pFilters.rangefilter)
        {
            case 1:
                {
                    dateStart = moment().subtract(7, 'days').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
            case 2:
                {
                    dateStart = moment().subtract(1, 'months').format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
            case 3:
                {
                    dateStart = moment("2023-06-01").format(formatdate);
                    dateEnd = moment().format(formatdate);
                    break;
                }
                default:{}
        }

        GetPagosRange(
            abortController.signal, dateStart, dateEnd).then((response) => {
                if (abortController.signal.aborted)
                        return;
                if (response.ok)
                {
                    response.json().then((data) =>{
                        setPayments(props.pFilters.rangefilter === 3 ? data.filter(f => f.status === 0) : data);
                        if (data.length === 0)
                        {
                            setStatus({
                                title: "No se encontraron resultados.", 
                                errors: [], 
                                loading: false, 
                                class: "warning"});
                        } else {
                            setStatus({
                                title: "", 
                                errors: [], 
                                loading: false, 
                                class: "info"});
                        }
                    });
                } else {
                    response.json().then((data) =>{
                        setStatus({
                            title: "Error al procesar la peticion", 
                            errors: [data.Message], 
                            loading: false, 
                            class: "error"});
                    });
                }
            }).catch(function(error) { 
                if (!abortController.signal.aborted)
                {
                    console.log(error);
                    setStatus({
                        title: "Error de conexión", 
                        errors: ["Compruebe su conexion de internet"], 
                        loading: false, 
                        class: "error"});
                }
            });

        return () => {abortController.abort();};
    }, [props.pFilters.datefilter, props.pFilters.datefilterend, props.pFilters.rangefilter, refresh]);

    //CHANGE FILTERS
    useEffect(() => {

    }, [props.pFilters.search, props.pFilters.sortfilter, props.pFilters.sortfilterType]);

    const sortOptions = [
        {value:2, label:"Fecha"},
        {value:1, label:"Status"},
        {value:0, label:"Folio"},
        {value:4, label:"Monto"}];

    const search = props.pFilters.search;
    const sortfilter = props.pFilters.sortfilter;
    const sortfilterType = props.pFilters.sortfilterType;

    //SHORT AND FILTER
    const list_array = [];
    payments.forEach(element => {
        if (search.length > 0)
        {
            if (element.id.toString().includes(search) || element.facturasPagar.toLowerCase().includes(search.toLowerCase()))
            {
                list_array.push(Object.values(element));
            }
        } else {
            list_array.push(Object.values(element));
        }
    });

    list_array.sort(function(a, b){
        if (a[sortfilter] > b[sortfilter]) {
            return sortfilterType;
            } else if (a[sortfilter] < b[sortfilter]) {
            return sortfilterType*-1;
            } else {
            return 0;
            }
    });

    const sorted_data = []; 
    list_array.forEach(element => {
        sorted_data.push({
            id: element[0],
            status: element[1],
            fecha: element[2],
            formaPago: element[5],
            total: element[9]
        });
    });

    return(
        <section>
            <CommunFilters
            search_ph="Buscar Folio o Factura"
            search_value={search}
            filters={["Rango fechas", "Ultima semana", "Ultimo mes", "Pendientes"]}
            setSearch={(v) => props.changePaymentsFilters({...props.pFilters, search: v})}
            date_value={props.pFilters.datefilter.format("YYYY-MM-DD")}
            setDateFilter={(v) => props.changePaymentsFilters({...props.pFilters, datefilter: moment(v)})}
            date_end_value={props.pFilters.datefilterend.format("YYYY-MM-DD")}
            setDateFilterEnd={(v) => props.changePaymentsFilters({...props.pFilters, datefilterend: moment(v)})}
            sort_filter_value={sortfilter}
            setSortFilter={(v) => props.changePaymentsFilters({...props.pFilters, sortfilter: v})}
            sort_filter_type={sortfilterType}
            setSortFilterType={(v) => props.changePaymentsFilters({...props.pFilters, sortfilterType: v})}
            sort_ops={sortOptions}
            range_filter_value={props.pFilters.rangefilter}
            setRangeFilter={(v) => props.changePaymentsFilters({...props.pFilters, rangefilter: v})}
            disabled={status.loading}
            requestRefresh={() => setRefresh(!refresh)}
            />
            <Loading {...status}/>
            {payments.length > 0 ? <PaymentList payments={sorted_data}/> : <></>}
        </section>
    );
}
