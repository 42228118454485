import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Currency } from '../../Utils/Utils';

export default function OrdersList(props)
{
    const STATUS_LABELS = ["OC Por atender", "OC En revision", "OC Con contrarecibo", "OC Pagada sin complemento", "OC Concluida", "OC Cancelada"];
    const STATUS_VARIANTS = ["info", "warning", "orange", "purple", "success", "secondary"];

    const list_orders = props.orders.map((order, index) => 
        <tr key={index} className={"table-"+STATUS_VARIANTS[order.status]}>
            <td>
            <Link  to={`${order.id}`}>
                {order.id}
            </Link>
            </td>
            <td>{STATUS_LABELS[order.status]}{order.fechaCarga !== null && order.status === 1 ? ` (desde ${moment(order.fechaCarga).format("D MMM YYYY")})` : ""}</td>
            <td>{moment(order.fechaHora).format("ddd D MMM YYYY h:mm A")}</td>
            <td>{order.factura}</td>
            <td>{Currency.format(order.total)}</td>
        </tr>
    );

    return(
        <div className='p-3'>
            <Table bordered hover>
                <thead className='table-head-dark'>
                    <tr>
                        <th>Folio</th>
                        <th>Status</th>
                        <th>Fecha y hora de orden compra</th>
                        <th>Factura</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>
                    {list_orders}
                </tbody>
            </Table>
        </div>
    );
}