import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Currency } from '../../Utils/Utils';

export default function ReceiptsList(props)
{
    const STATUS_LABELS = ["CR Por atender","CR Pendiente de pago","CR Pagado","CR Cancelado"];
    const STATUS_COLORS = ["default","warning","success","danger"];

    const list_receipts = props.receipts.map((recp, index) => 
        <tr key={index} className={"table-"+STATUS_COLORS[recp.status]}>
            <td>
                <Link to={`${recp.id}`}>
                    {recp.id}
                </Link>
            </td>
            <td>{moment(recp.fecha).format("ddd D MMM YYYY h:mm A")}</td>
            <td>{STATUS_LABELS[recp.status]}</td>
            <td>{recp.concepto}</td>
            <td>{recp.facturas}</td>
            <td>{Currency.format(recp.total)}</td>
            <td>{moment(recp.fechaPago).format("ddd D MMM YYYY")}</td>
        </tr>
    );

    return(
        <div className='p-3'>
            <Table bordered hover>
                <thead className='table-head-dark'>
                    <tr>
                        <th>Folio</th>
                        <th>Fecha</th>
                        <th>Status</th>
                        <th>Concepto</th>
                        <th>Facturas</th>
                        <th>Total</th>
                        <th>Fecha Pago</th>
                    </tr>
                </thead>
                <tbody>
                    {list_receipts}
                </tbody>
            </Table>
        </div>
    );
}