import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Currency } from '../../Utils/Utils';

export default function PaymentList(props)
{
    const STATUS_LABELS = ["Falta complemento pago", "Completado"];
    const STATUS_COLORS = ["default", "success"];

    const list_payments = props.payments.map((pay, index) => 
        <tr key={index} className={"table-"+STATUS_COLORS[pay.status]}>
            <td>
            <Link  to={`${pay.id}`}>
                {pay.id}
            </Link>
            </td>
            <td>{moment(pay.fecha).format("ddd D MMM YYYY h:mm A")}</td>
            <td>{STATUS_LABELS[pay.status]}</td>
            <td>{pay.formaPago}</td>
            <td>{Currency.format(pay.total)}</td>
        </tr>
    );

    return(
        <div className='p-3'>
            <Table bordered hover>
                <thead className='table-head-dark'>
                    <tr>
                        <th>Folio</th>
                        <th>Fecha</th>
                        <th>Status</th>
                        <th>Forma de pago</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {list_payments}
                </tbody>
            </Table>
        </div>
    );
}