import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Title(props)
{
    return(
        <h2 className='text-center mb-4'>
            <FontAwesomeIcon icon={props.icon}/> {props.text}
        </h2>
    );
}