import React, { useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import Loading from '../Components/Loading';
import { CheckPassword } from '../Utils/Utils';
import { GetError, HandleError } from '../Services/Net/NetService';
import { UpdatePassword } from '../Services/Net/ProveedoresService';

export default function ProfilePage(props)
{
    const [newpsw, setNewpsw] = useState("");
    const [newpswcom, setNewpswcom] = useState("");
    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});

    function UpdatePasswordHandler(e)
    {
        e.preventDefault();
        
        if (!CheckPassword(newpsw))
        {
            setStatus({
                title: "Contraseña invalida", 
                errors: ["Debe tener de 8 a 16 caracteres", "Solo permite letras, numeros o los siguientes simbolos !@#$%^&*"], 
                loading: false, 
                class: "error"});
            return;
        }

        if (newpsw !== newpswcom)
        {
            setStatus({
                title: "Contraseña invalida", 
                errors: ["Las contraseñas no coinciden"], 
                loading: false, 
                class: "error"});
            return;
        }

        const abortController = new AbortController();
        const pswb64 = btoa(newpsw);
        const dataToSend = {
            Username: props.userData.Fullname,
            Password: pswb64
        };

        setStatus({
            title: "Actualizando...", 
            errors: [], 
            loading: true, 
            class: "info"});

        UpdatePassword(dataToSend,abortController.signal).then(
            (response) =>{
                if (abortController.signal.aborted)
                return;
        
                if (response.ok)
                {
                    setStatus({
                        title: "Contraseña actualizada correctamente", 
                        errors: [], 
                        loading: false, 
                        class: "success"});
                } else {
                HandleError(response).then((error) => {
                    setStatus({
                    title: GetError(error), 
                    errors: [], 
                    loading: false, 
                    class: "error"});
                });
                }
            }
            ).catch(function(error) { 
            if (!abortController.signal.aborted){
                setStatus({
                title: "Error de conexión", 
                errors: ["Compruebe su conexion de internet"], 
                loading: false, 
                class: "error"});
            }
        });
    }

    return(
        <>
            <Container className='pt-5'>
                <Card style={{ width: '20rem' }} className='m-auto'>
                    <Card.Body>
                        <Card.Title>{props.userData.Fullname}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Cambio de contraseña</Card.Subtitle>
                        <Form onSubmit={UpdatePasswordHandler}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nueva Contraseña</Form.Label>
                                <Form.Control type="password" onChange={e => setNewpsw(e.target.value)} value={newpsw}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Repetir Contraseña</Form.Label>
                                <Form.Control type="password" onChange={e => setNewpswcom(e.target.value)} value={newpswcom}/>
                            </Form.Group>
                            <Button variant="primary" type="submit">Actualizar</Button>
                        </Form>
                    </Card.Body>
                </Card>
                <Loading {...status}/>
            </Container>
        </>
    )
}