import { GET, POST, UPLOAD } from './NetService';

const Action = "proveedor/";

export async function GetOrdersRange(bsignal, datestart, dateend)
{
    return await GET(Action+"ordenescompra", bsignal, {fechaInicial: datestart, fechaFinal: dateend});
}

export async function GetContrarecibosRange(bsignal, datestart, dateend)
{
    return await GET(Action+"contrarecibos", bsignal, {fechaInicial: datestart, fechaFinal: dateend});
}

export async function GetPagosRange(bsignal, datestart, dateend)
{
    return await GET(Action+"pagos", bsignal, {fechaInicial: datestart, fechaFinal: dateend});
}

export async function GetOrder(bsignal, orderId)
{
    return await GET(`${Action}ordenescompra/${orderId}`, bsignal);
}

export async function GetReceipt(bsignal, orderId)
{
    return await GET(`${Action}contrarecibos/${orderId}`, bsignal);
}

export async function GetPago(bsignal, paymentId)
{
    return await GET(`${Action}pagos/${paymentId}`, bsignal);
}

export async function UploadOCFiles(data,bsignal, orderId)
{
    return await UPLOAD(data, `${Action}ordenescompra/upload/${orderId}`, bsignal);
}

export async function UploadCOMFiles(data,bsignal, paymentId)
{
    return await UPLOAD(data, `${Action}pagos/upload/${paymentId}`, bsignal);
}

export async function UpdatePassword(data,bsignal)
{
    return await POST(data, `${Action}perfil/psw`, bsignal);
}
