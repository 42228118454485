import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { GetOrder, UploadOCFiles } from '../../Services/Net/ProveedoresService';
import { Currency } from '../../Utils/Utils';
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faCode, faFileInvoice, faFileInvoiceDollar, faPaperclip, faReceipt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Title from '../Title';

export default function OrderDetail()
{
    let params = useParams();
    let refRem = useRef(null);
    let refCrd = useRef(null);
    let refCrdPDF = useRef(null);

    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});
    const [orderData, setOrderData] = useState(null);
    const [filesToUpload, setfilesToUpload] = useState({pdf: null, xml: null, rem: null, crd: null, crdpdf: null});

    // Select file PDF
    function onFilePDFChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            pdf: event.target.files[0]
        });
    };

    // Select file XML
    function onFileXMLChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            xml: event.target.files[0]
        });
    };

    // Select file PDF
    function onFileREMChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            rem: event.target.files[0]
        });
    };

    // Select file XML
    function onFileCRDChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            crd: event.target.files[0]
        });
    };

    // Select file XML
    function onFileCRDPDFChange(event) 
    {
        // Update the state
        setfilesToUpload({
            ...filesToUpload,
            crdpdf: event.target.files[0]
        });
    };

    // Clear REM
    function ClearFileREM()
    {
        refRem.current.value = "";
        setfilesToUpload({
            ...filesToUpload,
            rem: null
        });
    }

    // Clear CRD
    function ClearFileCRD()
    {
        refCrd.current.value = "";
        setfilesToUpload({
            ...filesToUpload,
            crd: null
        });
    }

    // Clear CRD PDF
    function ClearFileCRDPDF()
    {
        refCrdPDF.current.value = "";
        setfilesToUpload({
            ...filesToUpload,
            crdpdf: null
        });
    }

    function UploadFiles()
    {
        const formData = new FormData();

        setStatus({
            title: "Cargando archivos...", 
            errors: [], 
            loading: true, 
            class: "info"});

        formData.append(
            "factura.pdf",
            filesToUpload.pdf,
            filesToUpload.pdf.name
        );

        formData.append(
            "factura.xml",
            filesToUpload.xml,
            filesToUpload.xml.name
        );

        if (filesToUpload.rem !== null)
        {
            formData.append(
                "remision.pdf",
                filesToUpload.rem,
                filesToUpload.rem.name
            );
        }

        if (filesToUpload.crdpdf !== null)
        {
            formData.append(
                "nota_credito.pdf",
                filesToUpload.crdpdf,
                filesToUpload.crdpdf.name
            );
        }

        if (filesToUpload.crd !== null)
        {
            formData.append(
                "nota_credito.xml",
                filesToUpload.crd,
                filesToUpload.crd.name
            );
        }

        const abortController = new AbortController();
        UploadOCFiles(formData, abortController.signal, params.orderId).then((response) => {
            if (abortController.signal.aborted)
                return;
            
            if (response.ok)
            {
                response.json().then((data) =>{
                    if (data.errors.length > 0)
                    {
                        setStatus({
                            title: "Los documentos presentan los siguientes errores: ", 
                            errors: data.errors, 
                            loading: false, 
                            class: "error"});
                    } else {
                        setStatus({
                            title: "Factura procesada correctamente", 
                            errors: [], 
                            loading: false, 
                            class: "success"});
                        setOrderData({...orderData, orden: data.order});
                    }
                });
            } else{
                response.json().then((data) =>{
                    setStatus({
                        title: "Error al procesar la peticion", 
                        errors: [data.Message], 
                        loading: false, 
                        class: "error"});
                });
            }
        }).catch(function(error) { 
            if (!abortController.signal.aborted)
            {
                console.log(error);
                setStatus({
                    title: "Error de conexión", 
                    errors: ["Compruebe su conexion de internet"], 
                    loading: false, 
                    class: "error"});
            }
        });
    }

    //FETCH
    useEffect(() => {
        const abortController = new AbortController();

        setStatus({
            title: "Cargando Orden...", 
            errors: [], 
            loading: true, 
            class: "info"});

        GetOrder(abortController.signal, params.orderId).then((response) => {
            if (abortController.signal.aborted)
                return;
            
            if (response.ok)
            {
                response.json().then((data) =>{
                    setOrderData(data);
                    setStatus({
                        title: "", 
                        errors: [], 
                        loading: false, 
                        class: "info"});
                });
            } else{
                response.json().then((data) =>{
                    setStatus({
                        title: "Error al procesar la peticion", 
                        errors: [data.Message], 
                        loading: false, 
                        class: "error"});
                });
            }
        }).catch(function(error) { 
            if (!abortController.signal.aborted)
                {
                    console.log(error);
                    setStatus({
                        title: "Error de conexión", 
                        errors: ["Compruebe su conexion de internet"], 
                        loading: false, 
                        class: "error"});
                }
        });

        return () => {abortController.abort();};
    }, [params.orderId]);

    //REFRESH
    useEffect(() => {
    }, [orderData]);

    return(
        <section className='p-4'>
            <Loading {...status}/>
            {orderData ? <>
            <Title text={`Detalles de la orden de compra #${orderData.orden.id}`} icon={faFileInvoice}/>
            <OrderResume {...orderData.orden}/>
            <OrderActions 
                {...orderData.orden}
                {...filesToUpload} 
                onFilePDFChange={(e) => onFilePDFChange(e)}
                onFileXMLChange={(e) => onFileXMLChange(e)}
                onFileREMChange={(e) => onFileREMChange(e)}
                onFileCRDChange={(e) => onFileCRDChange(e)}
                onFileCRDPDFChange={(e) => onFileCRDPDFChange(e)}
                ClearFileREM={() => ClearFileREM()}
                ClearFileCRD={() => ClearFileCRD()}
                ClearFileCRDPDF={() => ClearFileCRDPDF()}
                refRem={refRem}
                refCrd={refCrd}
                refCrdPDF={refCrdPDF}
                UploadFiles={() => UploadFiles()}
                isLoading={status.loading}
            />
            <OrderArticles articles={orderData.articulos}/>
            <OrderCR receiptId={orderData.orden.contrareciboId}/>
            </> : <></>}
        </section>
    );
}

function OrderResume(props)
{
    const STATUS_LABELS = ["Por atender", "En revision", "Con contrarecibo", "Pagada sin complemento", "Concluida", "Cancelada"];
    const STATUS_VARIANTS = ["info", "warning", "orange", "purple", "success", "secondary"];

    const dateUploaded = props.UploadFilesDate === null ? "" : moment(props.UploadFilesDate).format("ddd D MMM YYYY h:mm A");
    console.log(props);
    return(
        <Container fluid>
            <Alert variant={STATUS_VARIANTS[props.status]}>
                <Row className='text-center'>
                    <Col>
                        <span>Orden generada el <br></br>{moment(props.fechaHora).format("ddd D MMM YYYY h:mm A")}</span><br></br>
                        <span>Factura {props.factura}</span>
                    </Col>
                    <Col>
                        <span>
                            <strong>
                            {STATUS_LABELS[props.status]}
                            </strong>
                            {props.UploadFilesDate === null ? <></>: <small><br></br>Archivos subidos el {dateUploaded}</small>}
                            </span>
                    </Col>
                    <Col>
                        <span>SUBTOTAL: {Currency.format(props.subtotal)}</span><br></br>
                        <span>IVA: {Currency.format(props.iva)}</span><br></br>
                        <span>TOTAL: {Currency.format(props.total)}</span>
                    </Col>
                </Row>
            </Alert>
        </Container>
    );
}

function OrderArticles(props)
{
    const list_articles = props.articles.map((art, index) => 
        <tr key={index}>
            <td>{art.descripcion}</td>
            <td>{Currency.format(art.costoPiezaOriginal)}</td>
            <td>{art.descuento ? art.descuento.toFixed(2) : '0'}%</td>
            <td>{Currency.format(art.costoPiezaPesos)}</td>
            <td>{art.cantidad}</td>
            <td>{Currency.format(art.iva)}</td>
            <td>{Currency.format(art.total)}</td>
            <td>{art.piezasPendientes}</td>
        </tr>
    );

    return(
        <Container fluid className='mt-2'>
            <h2>Articulos</h2>
            <Table>
                <thead>
                    <tr>
                        <th>Articulo</th>
                        <th>Costo Pieza</th>
                        <th>Descuento</th>
                        <th>Costo con descuento</th>
                        <th>Cantidad</th>
                        <th>IVA</th>
                        <th>Total</th>
                        <th>Piezas pendientes</th>
                    </tr>
                </thead>
                <tbody>
                    {list_articles}
                </tbody>
            </Table>
        </Container>
    )
}

function OrderCR(props)
{
    return(
        <Container fluid>
            <h2>Contra Recibo</h2>
            <Row>
                <Col>
                {props.receiptId === 0 ? <span>Sin Contrarecibo</span> 
                    : <Link to={`../../receipts/${props.receiptId}`}>Ver Contrarecibo</Link>}
                </Col>
            </Row>
        </Container>
    )
}

function OrderActions(props)
{
    if (props.status === 0)
    {
        return(
        <Form className='text-center'>
            <h3 className='mt-3'>Subir factura y documentos</h3>
            <h4 className='orangered mt-3'>Documentos Obligatorios</h4>
            <Row className="justify-content-md-center">
                <Col>
                    <Form.Group className="mb-3 orangered">
                        <Form.Label>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>&nbsp;
                            Adjuntar Factura *
                        </Form.Label>
                        <Form.Control type="file" accept='.pdf' onChange={(e) => props.onFilePDFChange(e)}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3 orangered">
                        <Form.Label>
                            <FontAwesomeIcon icon={faCode}/>&nbsp;
                            Adjuntar XML *
                        </Form.Label>
                        <Form.Control type="file" accept='.xml' onChange={(e) => props.onFileXMLChange(e)}/>
                    </Form.Group>
                </Col>
            </Row>
            <h4>Nota de credito (Opcional)</h4>
            <Row className="justify-content-md-center m-4">
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <FontAwesomeIcon icon={faReceipt}/>&nbsp;
                            Adjuntar Nota de credito PDF
                        </Form.Label>
                        <InputGroup>
                            <Form.Control type="file" accept='.pdf' ref={props.refCrdPDF} onChange={(e) => props.onFileCRDPDFChange(e)}/>
                            <Button variant="outline-secondary" onClick={() => props.ClearFileCRDPDF()}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <FontAwesomeIcon icon={faCode}/>&nbsp;
                            Adjuntar Nota de credito XML
                        </Form.Label>
                        <InputGroup>
                            <Form.Control type="file" accept='.xml' ref={props.refCrd} onChange={(e) => props.onFileCRDChange(e)}/>
                            <Button variant="outline-secondary" onClick={() => props.ClearFileCRD()}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <h4>Remision (Opcional)</h4>
            <Row className="justify-content-md-center m-4">
                <Col xs="12" sm="12" md="6" lg="4" xl="4" xxl="4">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <FontAwesomeIcon icon={faPaperclip}/>&nbsp;
                            Adjuntar Remision
                        </Form.Label>
                        <InputGroup>
                            <Form.Control type="file" accept='.pdf' ref={props.refRem} onChange={(e) => props.onFileREMChange(e)}/>
                            <Button variant="outline-secondary" onClick={() => props.ClearFileREM()}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button 
                        variant="success" 
                        className="item-center" 
                        disabled={props.xml === null || props.pdf === null || props.isLoading}
                        onClick={() => props.UploadFiles()}
                        >
                        <FontAwesomeIcon icon={faCloudArrowUp}/>&nbsp;
                        Subir Archivos
                    </Button>
                </Col>
            </Row>
        </Form>
        );
    } else {
        return(<></>);
    }

}