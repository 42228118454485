import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice,
        faFileInvoiceDollar, 
        faIdCard, 
        faReceipt, 
        faSignOutAlt
    } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import Logo from "../Assets/Images/Logo-HRSJ.png";
import {GetTitleHospital} from "../AppConfig";
import {Version} from '../Services/VersionService';

export default function Navbarmenu(props)
{
    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" className="vw100">
            <img src={Logo} alt="Logo" className="img_logo_navbar"/>
            <small className="version white fsp10">{Version}</small>
            <span className="titlenav">{GetTitleHospital()}</span>
            <span>&nbsp;</span>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <NavBarItem 
                        icon={faFileInvoice} 
                        link="/dashboard/orders" 
                        text="Ordenes"/>
                    <NavBarItem 
                        icon={faReceipt} 
                        link="/dashboard/receipts" 
                        text="Contrarecibos"/>
                    <NavBarItem 
                        icon={faFileInvoiceDollar} 
                        link="/dashboard/payments" 
                        text="Pagos"/>
                    <NavBarItem 
                        icon={faIdCard} 
                        link="/dashboard/profile" 
                        text="Perfil"/>
                </Nav>
                <UserMenu {...props} OnLogout={() => props.OnLogout()}/>
            </Navbar.Collapse>
        </Navbar>
    )
}

function UserMenu(props)
{
    return(
        
        <div className='mlauto'>
            <div className='fsp11 white'>
                {props.user.Fullname}
            </div>
            <div>
                <Nav className='mlauto'>
                    <button className="navlink" onClick={props.OnLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt}/>
                    <span>&nbsp;</span>
                    Cerrar Sesión
                    </button>
                </Nav>
            </div>
        </div>
    );
}

function NavBarItem(props)
{
    return(
        <NavLink to={props.link} className={({ isActive }) => isActive ? "navlinkactive p6" :"navlink p6"}>
            <div>
                <FontAwesomeIcon icon={props.icon}/>
                <span>&nbsp;</span>
                {props.text}
            </div>
        </NavLink>
    );
}