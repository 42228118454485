import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import moment from 'moment';
import Navbarmenu from '../Components/Navbarmenu';
import ReceiptsPage from './ReceiptsPage';
import OrdersPage from './OrdersPage';
import PaymentsPage from './PaymentsPage';
import ProfilePage from './ProfilePage';
import Footer from '../Components/Footer';

export default function DashboardPage(props)
{
    if (!props.user)
    {
        return(
            <Navigate to="/login" replace/>
        );
    } else {
        return(
            <Dashboard {...props} LogOut={() => props.LogOut()}/>
        );
    }
}

function Dashboard(props)
{
    const [ordersFilters, setOrdersFilters] = useState({
        datefilter: moment(),
        datefilterend: moment(),
        search: "",
        sortfilter: 0,
        sortfilterType: -1,
        rangefilter: 0
    });

    const [receiptsFilters, setReceiptsFilters] = useState({
        datefilter: moment(),
        datefilterend: moment(),
        search: "",
        sortfilter: 0,
        sortfilterType: -1,
        rangefilter: 0
    });

    const [paymentsFilters, setPaymentsFilters] = useState({
        datefilter: moment(),
        datefilterend: moment(),
        search: "",
        sortfilter: 0,
        sortfilterType: -1,
        rangefilter: 0
    });

    return(
        <div id="dashboard">
            <Navbarmenu {...props} 
                OnLogout={() => props.LogOut()}
                SwitchHosp={() => props.SwitchHosp()}/>
            <div className="ptnv h100 ofscroll mb-2">
                <Routes>
                    <Route path="orders/*" element={
                        <OrdersPage 
                            ordersFilters={ordersFilters} 
                            changeOrdersFilters={(f) => setOrdersFilters(f)}/>
                    }   />
                    <Route path="receipts/*" element={
                        <ReceiptsPage
                            receiptsFilters={receiptsFilters}
                            changeReceiptsFilters={(f) => setReceiptsFilters(f)}
                        />
                    } />
                    <Route path="payments/*" element={
                        <PaymentsPage
                            paymentsFilters={paymentsFilters}
                            changePaymentsFilters={(f) => setPaymentsFilters(f)}
                        />
                    } />
                    <Route path="profile" element={
                        <ProfilePage userData={props.user}/>
                    } />                    
                    <Route path='*' element={
                        <Navigate to="orders" replace/>
                    } />
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}