import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { GetReceipt } from '../../Services/Net/ProveedoresService';
import Loading from '../Loading';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import Title from '../Title';
import ReceiptPrint from '../Prints/ReceiptPrint';

export default function ReceiptDetail()
{
    let params = useParams();

    const [status, setStatus] = useState({title: "", errors: [], loading: false, class: "error"});
    const [ReceiptData, setReceiptData] = useState(null);

    //FETCH
    useEffect(() => {
        const abortController = new AbortController();

        setStatus({
            title: "Cargando contrarecibo...", 
            errors: [], 
            loading: true, 
            class: "info"});

        GetReceipt(abortController.signal, params.receiptId).then((response) => {
            if (abortController.signal.aborted)
                return;
            
            if (response.ok)
            {
                response.json().then((data) =>{
                    setReceiptData(data);
                    setStatus({
                        title: "", 
                        errors: [], 
                        loading: false, 
                        class: "info"});
                });
            } else{
                response.json().then((data) =>{
                    setStatus({
                        title: "Error al procesar la peticion", 
                        errors: [data.Message], 
                        loading: false, 
                        class: "error"});
                });
            }
        }).catch(function(error) { 
            if (!abortController.signal.aborted)
            {
                console.log(error);
                setStatus({
                    title: "Error de conexión", 
                    errors: ["Compruebe su conexion de internet"], 
                    loading: false, 
                    class: "error"});
            }
        });

        return () => {abortController.abort();};
    }, [params.receiptId]);

    return(
        <section className='p-4'>
            <Loading {...status}/>
            {ReceiptData ? <>
            <Title text={`Detalles del contrarecibo #${ReceiptData.id}`} icon={faReceipt}/>
            <ReceiptPrint {...ReceiptData}/>
            </> : <></>}
        </section>
    );
}