import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import Loading from '../Components/Loading';
import Checked from '../Components/Checked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Assets/Images/Logo-HRSJ-original.png';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import cookie from 'react-cookies';
import {Version} from '../Services/VersionService';

export default function LoginPage(props)
{
    if (props.user)
    {
        return(
            <Navigate to="/dashboard/orders" replace/>
        );
    } else {
    return(
        <section className='tc h100 bg_cyan'>
            <Card className='p16 ic bshadow loginbox'>
                <img src={Logo} width='256px' alt='logo'/>
                <Card.Body className='wp384'>
                    <Card.Title className="tc"><h2>PROVEEDORES</h2></Card.Title>
                    <LoginForm {...props} 
                            onSubmit={(u,p) => props.onSubmit(u,p)}
                            ChangeHosp={(v) => props.ChangeHosp(v)}
                            setRemember={(v) => props.setRemember(v)}
                            />
                    <Loading {...props.status}/>
                </Card.Body>
                <small className="text-muted">{Version}</small>
            </Card>
        </section>
        );
    }
}

function LoginForm(props)
{
    const defaultuser = cookie.load('hrsj_prv_defaultuser');
    const [user, setUser] = useState(defaultuser===undefined?"":defaultuser);
    const [password, setPassword] = useState("");

    function handleSubmit(event) {
        event.preventDefault();
    }

    return(
    <form onSubmit={e => {handleSubmit(e); props.onSubmit(user,password)}}>
        <div className="form-group m-2">
            <FontAwesomeIcon icon={faUser}/>
            <span>&nbsp;</span><span>&nbsp;</span>
            <label htmlFor="inp_mail" className='m-2'><strong>RFC</strong></label>
            <input className="form-control" id="inp_user" placeholder="RFC"
            value={user} onChange={e => setUser(e.target.value)} name="user"
            disabled={props.status.loading} required/>
        </div>
        <div className="form-group m-2">
            <FontAwesomeIcon icon={faLock}/>
            <span>&nbsp;</span><span>&nbsp;</span>
            <label htmlFor="inp_pass" className='m-2'><strong>Contraseña</strong></label>
            <input type="password" className="form-control" id="inp_pass" placeholder="Contraseña"
            value={password} onChange={e => setPassword(e.target.value)} name="password"
            disabled={props.status.loading} required/>
            <div className="error">
                {props.message}
            </div>
        </div>
        <div className="form-group p-2">
            <Button variant="outline-secondary" disabled={props.status.loading} 
                    className='m4' onClick={() => props.ChangeHosp('LC')}>
                <Checked ison={props.hospital==='LC'}/> Lazaro Cardenas
            </Button>
            <Button variant="outline-secondary" disabled={props.status.loading} 
                    className='m4' onClick={() => props.ChangeHosp('VR')}>
                <Checked ison={props.hospital==='VR'}/> Valle Real
            </Button>
        </div>
        <div className="form-group m-2">
            <Button variant="outline-dark" disabled={props.status.loading} 
                    className='m4' onClick={() => props.setRemember(!props.remember)}>
                <Checked ison={props.remember}/> Recordar Proveedor
            </Button>
        </div>
        <div className='p-2'>
            <BtnSubmit status={props.status}/>
        </div>
    </form>
    );
}

function BtnSubmit(props)
{
    if (!props.status.loading)
    {
        return(
            <Button type="submit" style={{width:'200px'}} className="btn btn-info">
                <FontAwesomeIcon icon={faSignInAlt}/>
                <span>&nbsp;</span>
                ENTRAR      
            </Button>
        );
    } else {
        return(<></>);
    }
}