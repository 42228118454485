import cookie from 'react-cookies';

export const Currency = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  currencyDisplay: "symbol"
});

export function GetBirthDay(dateborn)
{
  if (dateborn === undefined)
    return -1;
  
  const birth = new Date(dateborn);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();
  if (m < 0 || (m===0 && today.getDate() < birth.getDate()))
    age--;
  return age;
}

export function GetHospital()
{
  return cookie.load('hrsj_prv_hospital') === undefined ? 'LC' : cookie.load('hrsj_prv_hospital');
}

export function IsInHospital()
{
  const href = window.location.href;
  return href.includes("10.0.") || href.includes("localhost:3000");
}

export function Debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export function StringIsEmptyOrNull(str)
{
  return str === null || str.match(/^ *$/) !== null;
}

export function CheckPassword(str)
{
    var re = /[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    return re.test(str);
}