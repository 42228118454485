import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Logo from "../../Assets/Images/Logo-HRSJ-original.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Currency } from '../../Utils/Utils';
import { useReactToPrint } from 'react-to-print';

export default function ReceiptPrint(props)
{
    const docRef = useRef(null);
    const bmRef = useRef(null);
    const conRef = useRef(null);
    const [hgDoc, setHgDoc] = useState(1);
    
    // const handleDownload = useReactToPrint({
    //     print: async (printIframe: HTMLIframeElement) => {
    //         // Do whatever you want here, including asynchronous work
    //         await generateAndSavePDF(printIframe);
    //     },
    // });

    useEffect(() => {
        const hDocCm = (conRef.current.clientHeight / bmRef.current.clientHeight) -1;
        const pagesCm = hDocCm / 23.6;
        const diference = (1 - (pagesCm % 1)) * 23.6;
        console.log(`doc cm:${hDocCm}, pages:${pagesCm}, dif:${diference}`);
        setHgDoc(diference);
    },[conRef]);

    const handlePrint = useReactToPrint({
        content: () => docRef.current,
        onBeforePrint: () => {
            // const hDocCm = (conRef.current.offsetHeight / bmRef.current.offsetHeight) -1;
            // const pagesCm = hDocCm / 26.1;
            // const diference = (1 - (pagesCm % 1)) * 26.1;
            // console.log(`doc cm:${hDocCm}, pages:${pagesCm}, dif:${diference}`);
        }
    });

    return(
        <section>
            <Row className='text-center mt-4'>
                <Col>
                    <Button variant='primary' onClick={handlePrint}>
                        <FontAwesomeIcon icon={faPrint}/> Imprimir
                    </Button>
                </Col>
            </Row>
            <div ref={docRef}>
                <PrintDocument {...props} bmRef={bmRef} conRef={conRef} hm={hgDoc}/>
            </div>
        </section>
    );
}

const PrintDocument = (props) => {
    
    const list_facturas = props.facturas.map((f, index) => 
        <tr key={index}>
            <td>{f.id}</td>
            <td>{f.factura1}</td>
            <td>{f.concepto}</td>
            <td>{Currency.format(f.subtotal)}</td>
            <td>{Currency.format(f.iva)}</td>
            <td>{Currency.format(f.flete)}</td>
            <td>{Currency.format(f.ivaFlete)}</td>
            <td>{Currency.format(f.retencionIsr)}</td>
            <td>{Currency.format(f.retencionIva)}</td>
            <td>{Currency.format(f.retencionFlete)}</td>
            <td>{Currency.format(f.rtp)}</td>
            <td>{Currency.format(f.total)}</td>
        </tr>
    );

	return (
        <>
            <table className='report-container m-auto'>
                <thead className='report-header'>
                    <tr>
                        <th className="report-header-cell">
                            <Row className='page-header'>
                                <Col>
                                    <Image src={Logo} className='wp128'/>
                                </Col>
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tfoot className="report-footer">
                    <tr>
                        <td className="report-footer-cell">
                            <Row className='text-center page-footer'>
                                <Col><small>Hospital Real San José, AV. Lázaro Cárdenas No. 4149 Col. Jardines de San Ignacio Zapopan. Jal. México C.P. 45040</small></Col>
                            </Row>
                        </td>
                    </tr>
                </tfoot>
                <tbody className="report-content" ref={props.conRef}>
                    <tr>
                        <td>
                            <Row className='text-center fsp18 mb-4'>
                                <Col>
                                <strong>CONTRARECIBO</strong>
                                </Col>
                            </Row>
                            <Table bordered hover>
                                <tbody>
                                    <tr>
                                        <td><strong>Folio</strong><br></br><span>{props.id}</span></td>
                                        <td colSpan={2}><strong>Empresa</strong><br></br><span>{props.empresa}</span></td>
                                        <td><strong>Fecha</strong><br></br><span>{moment(props.fecha).format("DD/MM/YYYY")}</span></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}><strong>Proveedor</strong>  <span>{props.beneficiario}</span></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}><strong>Fecha de Pago:</strong> <span>{moment(props.fechaPago).format("DD/MM/YYYY")}</span></td>
                                        <td colSpan={2}><strong>Moneda:</strong>    <span>{props.moneda}</span></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <strong>Facturas</strong>
                            <Table hover className='fsp12'>
                                <thead>
                                    <tr>
                                        <th>FOLIO</th>
                                        <th>FACTURA</th>
                                        <th>TIPO</th>
                                        <th>SUBTOTAL</th>
                                        <th>IVA</th>
                                        <th>FLETE</th>
                                        <th>IVA FLETE</th>
                                        <th>R.ISR</th>
                                        <th>R.IVA</th>
                                        <th>R.FLETE</th>
                                        <th>RTP</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list_facturas}
                                </tbody>
                            </Table>
                            <Row className='fsp14'>
                                <Col>
                                    <div className='mt-5'>
                                        <span>{props.responsable}</span><br></br>
                                        <span>Tel. +52 33-10-78-89-00 ext. 3009 - 3118</span>
                                    </div>
                                </Col>
                                <Col>
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <td className='tr'><strong>SUBTOTAL:</strong></td>
                                                <td className='tl'>{Currency.format(props.subtotal)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>IVA:</strong></td>
                                                <td className='tl'>{Currency.format(props.iva)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>FLETE:</strong></td>
                                                <td className='tl'>{Currency.format(props.flete)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>IVA FLETE:</strong></td>
                                                <td className='tl'>{Currency.format(props.ivaFlete)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>RETENCION ISR:</strong></td>
                                                <td className='tl'>{Currency.format(props.retencionIsr)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>RETENCION IVA:</strong></td>
                                                <td className='tl'>{Currency.format(props.retencionIva)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>RETENCION FLETE:</strong></td>
                                                <td className='tl'>{Currency.format(props.retencionFlete)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>RTP:</strong></td>
                                                <td className='tl'>{Currency.format(props.rtp)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>RETENCION (RESICO):</strong></td>
                                                <td className='tl'>{Currency.format(props.retencionResico)}</td>
                                            </tr>
                                            <tr>
                                                <td className='tr'><strong>TOTAL:</strong></td>
                                                <td className='tl'>{Currency.format(props.total)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <div ref={props.bmRef} style={{height: `${props.hm}cm`}}></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};